/* Estilos para o seletor de horário */
.custom-time-class {
  float: left; /* Alinhar à esquerda para que fiquem lado a lado */
  width: 50%; /* Defina a largura desejada para o seletor de horário */
  box-sizing: border-box; /* Mantenha o tamanho total incluindo as bordas e preenchimentos */
}

/* Estilos para o seletor de data */
.custom-calendar-class {
  float: left; /* Alinhar à esquerda para que fiquem lado a lado */
  width: 50%; /* Defina a largura desejada para o seletor de data */
  box-sizing: border-box; /* Mantenha o tamanho total incluindo as bordas e preenchimentos */
}
