.chart-container {
    width: 100%;
    height: 300px;
  }
  
  @media (max-width: 768px) {
    .chart-container {
      width: 120%;
      margin-left: -20%;
      margin-top: -8% !important;
    }
  }